import product from '../Img/ParadisePizaa.png'
import rating from '../Img/rating.png'
const OrderData = [
    {
        id:0,
        dish: "Fiery Jalapeno & Paprika",
        view_btn: "view all(3)",
        product_img: product,
        deliver_date: 'Deliverd Yesterday.1003.Boradways Ave',
        product_des: 'The awesome foursome! Golden corn, black olives,.. ',
        reciept: "see receipt",
        rate: "Rate & Reviews",
        rating_img: rating,
        order_btn: 'reorder',
        more_btn: "view more",
    },
    {
        id:1,
        dish: "Fiery Jalapeno & Paprika",
        view_btn: "view all(3)",
        product_img: product,
        deliver_date: 'Deliverd Yesterday.1003.Boradways Ave',
        product_des: 'The awesome foursome! Golden corn, black olives,.. ',
        reciept: "see receipt",
        rate: "Rate & Reviews",
        rating_img: rating,
        order_btn: 'reorder',
        more_btn: "view more",
    },
    {
        id:2,
        dish: "Fiery Jalapeno & Paprika",
        view_btn: "view all(3)",
        product_img: product,
        deliver_date: 'Deliverd Yesterday.1003.Boradways Ave',
        product_des: 'The awesome foursome! Golden corn, black olives,.. ',
        reciept: "see receipt",
        rate: "Rate & Reviews",
        rating_img: rating,
        order_btn: 'reorder',
        more_btn: "view more",
    },
    {
        id:3,
        dish: "Fiery Jalapeno & Paprika",
        view_btn: "view all(3)",
        product_img: product,
        deliver_date: 'Deliverd Yesterday.1003.Boradways Ave',
        product_des: 'The awesome foursome! Golden corn, black olives,.. ',
        reciept: "see receipt",
        rate: "Rate & Reviews",
        rating_img: rating,
        order_btn: 'reorder',
        more_btn: "view more",
    },
    {
        id:4,
        dish: "Fiery Jalapeno & Paprika",
        view_btn: "view all(3)",
        product_img: product,
        deliver_date: 'Deliverd Yesterday.1003.Boradways Ave',
        product_des: 'The awesome foursome! Golden corn, black olives,.. ',
        reciept: "see receipt",
        rate: "Rate & Reviews",
        rating_img: rating,
        order_btn: 'reorder',
        more_btn: "view more",
    },
    {
        id:5,
        dish: "Fiery Jalapeno & Paprika",
        view_btn: "view all(3)",
        product_img: product,
        deliver_date: 'Deliverd Yesterday.1003.Boradways Ave',
        product_des: 'The awesome foursome! Golden corn, black olives,.. ',
        reciept: "see receipt",
        rate: "Rate & Reviews",
        rating_img: rating,
        order_btn: 'reorder',
        more_btn: "view more",
    },
]
export default OrderData