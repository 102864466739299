const Cities=[
    {
        id:0,
        cityName:"Amritsar"
    },
    {
        id:1,
        cityName:"Ludhiana"
    },
    {
        id:2,
        cityName:"Jalandhar"
    },
    {
        id:3,
        cityName:"Patiala"
    },
    {
        id:4,
        cityName:"Bathinda"
    },
    {
        id:5,
        cityName:"Hoshiarpur"
    },
    {
        id:6,
        cityName:"Mohali (SAS Nagar)"
    },
    {
        id:7,
        cityName:"Pathankot"
    },
    {
        id:8,
        cityName:"Moga"
    },
    {
        id:9,
        cityName:"Batala"
    },
    {
        id:10,
        cityName:"Sangrur"
    },
    {
        id:11,
        cityName:"Khanna"
    },
    {
        id:12,
        cityName:"Firozpur"
    },
    {
        id:13,
        cityName:"Faridkot"
    },
    {
        id:14,
        cityName:"Rajpura"
    },
];
export default Cities
