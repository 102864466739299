import React from 'react';
import Nav from './Nav';
import './CSS/Header.css';
const Header = () => {

  return (
    <>
    <header className=''>
      <Nav/>
    </header>

    </>
    
  );
};

export default Header;
