import img from '../Img/footerinstaimg.png'
import img1 from '../Img/footerinstaimg1.png'
import img2 from '../Img/footerinstaimg2.png'
import img3 from '../Img/footerinstaimg3.png'
import img4 from '../Img/footerinstaimg4.png'
import img5 from '../Img/footerinstaimg5.png'
import img6 from '../Img/footerinstaimg6.png'
import img7 from '../Img/footerinstaimg7.png'
import img8 from '../Img/footerinstaimg8.png'
const FooterData=[
    {
        id:0,
        image:img,
    },
    {
        id:1,
        image:img1,
    },
    {
        id:2,
        image:img2,
    },  {
        id:3,
        image:img3,
    },
    {
        id:4,
        image:img4,
    },
    {
        id:5,
        image:img5,
    },
    {
        id:6,
        image:img6,
    },
    {
        id:7,
        image:img7,
    },
    {
        id:8,
        image:img8,
    },
    
];
export default FooterData