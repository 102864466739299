import lunch from '../Img/servicefood.png';

const Data=[

    {
        id:0,
        img:lunch,
        title:"BREAKFAST",
    },
    {
        
        id:1,
        img:lunch,
        title:"LUNCH",
    },
    {
        
        id:2,
        img:lunch,
        title:"DINNER",
    },
]
export default Data