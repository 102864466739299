import blogimg from '../Img/blogimage.jpg'
const BlogData=[
    {
        id:0,
        blogimg:blogimg,
        date:[
            {
                month:'APRIL',
                date:7,
                year:2004,
            },
        ],
        time:'5 mint before',
        title:"A Chief People Officer's Guide to Influencing the C-Suite",
        description:"'A CEO's perspective on how Chief People Officers can meaningfully influence leaders at the highest levels of an organization ",
        admin:"Pando"
    },
    {
        id:1,
        blogimg:blogimg,
        date:[
            {
                month:'APRIL',
                date:7,
                year:2004,
            },
        ],
        time:'5 mint before',
        title:"A Chief People Officer's Guide to Influencing the C-Suite",
        description:"'A CEO's perspective on how Chief People Officers can meaningfully influence leaders at the highest levels of an organization ",
        admin:"Pando"
    },
    {
        id:2,
        blogimg:blogimg,
        date:[
            {
                month:'APRIL',
                date:7,
                year:2004,
            },
        ],
        time:'5 mint before',
        title:"A Chief People Officer's Guide to Influencing the C-Suite",
        description:"'A CEO's perspective on how Chief People Officers can meaningfully influence leaders at the highest levels of an organization ",
        admin:"Pando"
    },
    {
        id:4,
        blogimg:blogimg,
        date:[
            {
                month:'APRIL',
                date:7,
                year:2004,
            },
        ],
        time:'5 mint before',
        title:"A Chief People Officer's Guide to Influencing the C-Suite",
        description:"'A CEO's perspective on how Chief People Officers can meaningfully influence leaders at the highest levels of an organization ",
        admin:"Pando"
    },
];
export default BlogData