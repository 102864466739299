import menu from '../Img/menuimg.png'
const MenuData = [
    {
        id:0,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:1,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:2,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:3,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:4,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:5,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:6,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:7,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:8,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
    {
        id:9,
        heading: "Veg. Noodles",
        para: "Although food price increases may be a good reason to go on a diet, these unrelenting cost hikes on necessities are going to hurt many people.",
        btn: "BROWSE MENU",
        img: menu,
    },
]
export default MenuData