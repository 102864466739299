import pizza from '../Img/RestoPizza2.png';
import pizza2 from '../Img/Restopizaa.png'
const SharedData = {
    facebook: [
        {
            id: 0,
            img: pizza,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 1,
            img: pizza2,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 2,
            img: pizza,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 3,
            img: pizza,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
    ],
    instagram: [
        {
            id: 0,
            img: pizza2,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 1,
            img: pizza2,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 2,
            img: pizza,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
    ],
    twitter: [
        {
            id: 0,
            img: pizza,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 1,
            img: pizza,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 2,
            img: pizza2,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
    ],
    whatsapp: [
        {
            id: 0,
            img: pizza2,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 1,
            img: pizza,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
        {
            id: 2,
            img: pizza2,
            product: 'Lorem Ipsum Dummy',
            description: 'Lorem Ipsum Dummy Description ipsum text harry',
            price: '40.00',
            price2: '50.00',
        },
    ],

}
export default SharedData;
