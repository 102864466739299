
import React from 'react'
import SideBar from './SideBar'

const Admin = () => {
    return (
        <>
            <SideBar />
        </>
    )
}

export default Admin