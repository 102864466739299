import { FaHeart } from "react-icons/fa";
import img from '../Img/menuimg.png'
import stars from '../Img/rating.png'
const item = {
    All: [
        {
            id: 0,
            dishes: "Chicken",
            review: stars,
            product: "Crispy Chicken Double Patty ",
            price: "170.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 1,
            dishes: "Chicken",
            review: stars,
            product: "Crispy Veg Burger ",
            price: "130.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 2,
            dishes: "Chicken",
            review: stars,
            product: "Ajwain Cookies Box",
            price: "136.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 3,
            dishes: "Chicken",
            review: stars,
            product: "Jeera Cookies Box",
            price: "132.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 4,
            dishes: "Chicken",
            review: stars,
            product: "Rajma Rice Desi Ghee + Mango Juice ",
            price: "133.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 5,
            dishes: "Chicken",
            review: stars,
            product: "Tuscan Chicken",
            price: "170.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 6,
            dishes: "Chicken",
            review: stars,
            product: "Tuscan Chicken",
            price: "150.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 7,
            dishes: "Chicken",
            review: stars,
            product: "Mattar Paneer Rice Box with Mango Juic ",
            price: "155.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 8,
            dishes: "Chicken",
            review: stars,
            product: "Chilli Paneer + Mango Juice",
            price: "150.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 9,
            dishes: "Chicken",
            review: stars,
            product: "Yellow Dal Tadka",
            price: "130.00",
            like: <FaHeart />,
            img: img,
        },


    ],
    Burger: [
        {
            id: 0,
            product: "Lamb Burger.",
            review: stars,
            dishes: "Burger ",
            price: "170.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 1,
            product: "Chicken Burger.",
            review: stars,
            dishes: "burger ",
            price: "130.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 2,
            product: "Cheese Burger.",
            review: stars,
            dishes: "Burger",
            price: "136.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 3,
            dishes: "Burger",
            review: stars,
            product: "Turkey Burger.",
            price: "132.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 4,
            dishes: "Burger",
            review: stars,
            product: "Turkey Burger.",
            price: "132.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 5,
            dishes: "Burger",
            review: stars,
            product: "Turkey Burger.",
            price: "132.00",
            like: <FaHeart />,
            img: img,
        },
    ],
    Dosa: [
        {
            id: 0,
            dishes: "Dosa",
            review: stars,
            product: "Paneer Dosa, ",
            price: "170.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 1,
            dishes: "Dosa",
            review: stars,
            product: "Rava Dosa",
            price: "130.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 2,
            dishes: "Dosa",
            review: stars,
            product: "Mysore Masala Dosa ",
            price: "136.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 3,
            dishes: "Dosa",
            review: stars,
            product: "Crispy Paper Dosa.",
            price: "132.00",
            like: <FaHeart />,
            img: img,
        },
    ],
    Chicken: [
        {
            id: 0,
            dishes: "Chicken",
            review: stars,
            product: "Crispy Chicken Double Patty ",
            price: "170.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 1,
            dishes: "Chicken",
            review: stars,
            product: "Crispy Veg Burger ",
            price: "130.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 2,
            dishes: "Chicken",
            review: stars,
            product: "Ajwain Cookies Box",
            price: "136.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 3,
            dishes: "Chicken",
            review: stars,
            product: "Jeera Cookies Box",
            price: "132.00",
            like: <FaHeart />,
            img: img,
        },
    ],
    Vegetables: [
        {
            id: 0,
            dishes: "Vegetables",
            review: stars,
            product: "Spinach. ",
            price: "170.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 1,
            dishes: "Vegetables",
            review: stars,
            product: "Carrots. ",
            price: "130.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 2,
            dishes: "Vegetables",
            review: stars,
            product: "Broccoli.",
            price: "136.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 3,
            dishes: "Vegetables",
            review: stars,
            product: "Brussels sprouts.",
            price: "132.00",
            like: <FaHeart />,
            img: img,
        },
    ],
    Pasta: [
        {
            id: 0,
            dishes: "Pasta",
            review: stars,
            product: "Spaghetti Bolognaise. ",
            price: "170.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 1,
            dishes: "Pasta",
            review: stars,
            product: "Fettuccine Alfredo. ",
            price: "130.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 2,
            dishes: "Pasta",
            review: stars,
            product: "Pasta Carbonara.",
            price: "136.00",
            like: <FaHeart />,
            img: img,
        },
        {
            id: 3,
            dishes: "Pasta",
            review: stars,
            product: "Spaghetti alle Vongole.",
            price: "132.00",
            like: <FaHeart />,
            img: img,
        },
    ],
}



export default item