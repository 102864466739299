import productimg from '../Img/Restopizaa.png'
const TabelData = [
    {
        id: 0,
        label: '1',
        productname: "Productname",
        regularPrize: "23.00",
        SalePrize: "$17.00",
        Image: productimg,
        Catagory: "Catagory",
        SAQCode: 'saqcode',
        Description: "Lorem ipsum dolor sit amet consectetur ",
    },
    {
        id: 1,
        label: '1',
        productname: "Productname",
        regularPrize: "23.00",
        SalePrize: "$17.00",
        Image: productimg,
        Catagory: "Catagory",
        SAQCode: 'saqcode',
        Description: "Lorem ipsum dolor sit amet consectetur ",
    },
    {
        id: 2,
        label: '1',
        productname: "Productname",
        regularPrize: "23.00",
        SalePrize: "$17.00",
        Image: productimg,
        Catagory: "Catagory",
        SAQCode: 'saqcode',
        Description: "Lorem ipsum dolor sit amet consectetur ",
    },
    {
        id: 3,
        label: '1',
        productname: "Productname",
        regularPrize: "23.00",
        SalePrize: "$17.00",
        Image: productimg,
        Catagory: "Catagory",
        SAQCode: 'saqcode',
        Description: "Lorem ipsum dolor sit amet consectetur ",
    },
    {
        id: 4,
        label: '1',
        productname: "Productname",
        regularPrize: "23.00",
        SalePrize: "$17.00",
        Image: productimg,
        Catagory: "Catagory",
        SAQCode: 'saqcode',
        Description: "Lorem ipsum dolor sit amet consectetur ",
    },
];
export default TabelData